import React, { useState, memo, useMemo, useCallback } from 'react';
import { Trans, useTranslation, withTranslation, TFunction } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import moment, { Moment } from 'moment';

import { TextInput } from '@common/components/form/inputs/text';
import { DateInput } from '@common/components/form/inputs/date';
import { Toggle } from '@common/components/form/inputs/toggle';
import Group from '@common/components/form/group';
import Label from '@common/components/form/label';
import Row from '@common/components/form/row';
import { Button } from '@common/components/button';
import { AlertService } from '@common/services/alert';
import ImageItem from '@common/components/image-item/user';
import Icon from '@common/components/icon';
import Overview from '@common/components/overview';
import AudienceModalForm from './audience-modal-form';
import AudienceBadge from '@common/components/form/audience/audience-badge';

import { SURVEYS_UPDATE_SURVEY } from '@modules/survey/actions';
import { Api } from '@common/services/api';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import * as surveySelector from '../../selectors/surveys';

import { StoreState } from '@common/types/store';
import { ESurveyTypes } from '@modules/survey/definitions';
import { Survey } from '@modules/survey/types/objects';
import SurveyType from '../../components/survey-type';

import './survey-detail.scss';

type TitleRowProps = {
  title: string;
  setTitle: (value: string) => void;
  t: TFunction;
};

const TitleRow = memo(({ title, setTitle, t }: TitleRowProps) => {
  return (
    <Row className="TitleRow">
      <Group>
        <Label text={t('survey:form_survey_title_label')} maxLength={30} />
        <TextInput
          name="title"
          placeholder={t('survey:form_survey_title_placeholder')}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          maxLength={30}
        />
      </Group>
    </Row>
  );
});

type ExpiresAtRowProps = {
  expiresAt: Moment | null;
  onChange: (value: null | Moment) => void;
};

const ExpiresAtRow = memo(({ expiresAt, onChange }: ExpiresAtRowProps) => {
  const { t } = useTranslation();
  const unset = useCallback(() => onChange(null), [onChange]);
  return (
    <Row>
      <Group>
        <Label text={t('survey:form_label_expiration_date')} />
        <div className="expirationDate">
          <DateInput value={expiresAt} onChange={onChange} minDate={moment().add(1, 'day')} />
          {
            expiresAt ?
              <Icon className="unsetDate" type="delete" onClick={unset} /> :
              null
          }
        </div>
      </Group>
    </Row>
  );
});

type ButtonsProps = {
  t: TFunction;
  disabled: boolean;
  loading: boolean;
  onSubmit: () => void;
  onReset: () => void;
};

const Buttons = memo(({
  t, onReset, onSubmit, disabled, loading
}: ButtonsProps) => {
  return (
    <Row className="SurveyDetailButtons">
      <Group>
        <Button disabled={disabled} onClick={onReset}>
          { t('common:modal_footer_button_cancel') }
        </Button>
        <Button
          disabled={disabled}
          type="primary"
          isLoading={loading}
          onClick={onSubmit}
        >
          { t('common:save_changes') }
        </Button>
      </Group>
    </Row>
  );
});

export type Props = RouteComponentProps<{ id: string }> & {
  survey: Survey,
  t: (key: string) => string,
};

const SurveyDetailContainer = ({ survey, t }: Props) => {
  const [loading, setLoading] = useState(false);

  const originalTitle = survey.title || '';
  const originalAudience = survey.audience;
  const originalExpiresAt = survey.settings.expires_at ?
    moment(survey.settings.expires_at) :
    null;

  const [title, setTitle] = useState<string>(originalTitle);
  const [audience, setAudience] = useState(originalAudience);
  const [expiresAt, setExpiresAt] = useState(originalExpiresAt);
  const [shouldSendAudience, setShouldSendAudience] = useState(false);

  const fieldsChanged = useMemo(() => {
    return (
      title !== originalTitle ||
      audience.predicate_type !== originalAudience.predicate_type ||
      (
        JSON.stringify(audience.predicates) !==
        JSON.stringify(originalAudience.predicates)
      ) ||
      (
        expiresAt && originalExpiresAt ?
          !expiresAt.isSame(originalExpiresAt, 'day') :
          expiresAt !== originalExpiresAt
      )
    );
  }, [
    title, originalTitle,
    audience, originalAudience,
    expiresAt, originalExpiresAt
  ]);

  const onReset = useCallback(() => {
    setTitle(originalTitle);
  }, [originalTitle, setTitle]);

  const dispatch = useDispatch();
  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const orgId = survey.organisation_id;
      const url = `/v1/organisations/${orgId}/surveys/${survey.id}`;
      const payload: any = {
        title: title.trim(),
        settings: {},
      };
      // Implemented in scope of
      // https://linear.app/oneteam/issue/ONE-1554/change-updating-of-surveys-to-not-always-send-the-audience
      // We only update the audience when an admin explicity looked at the audience in the modal
      // Should be improved in scope of
      // https://linear.app/oneteam/issue/ONE-1559/add-boolean-refresh-to-audience-object
      if (shouldSendAudience) {
        payload.audience = {
          predicate_type: audience.predicate_type,
          predicates: audience.predicates.map((predicate: any) => {
            const obj = { ...predicate };
            delete obj.context;
            delete obj.id;
            return obj;
          })
        };
      }
      if (survey.survey_type !== ESurveyTypes.ONGOING) {
        payload.settings = {
          expires_at: expiresAt ? expiresAt.toISOString() : null
        };
      }
      const result = await Api.put(url, payload);
      dispatch({
        type: SURVEYS_UPDATE_SURVEY,
        item: result.data
      });
      setShouldSendAudience(false);
      AlertService.success(t('core:changes_saved'));
    } catch (error) {
      AlertService.error(t('survey:survey_save_error'));
    } finally {
      setLoading(false);
    }
  }, [
    survey, title, audience, expiresAt,
    dispatch, setLoading, t
  ]);

  const [showAudienceModal, setShowAudienceModal] = useState(false);
  const closeAudienceModal = useCallback(() => {
    setShowAudienceModal(false);
  }, [setShowAudienceModal]);
  const onAudienceModalSubmit = useCallback((data) => {
    setAudience(data.audience);
  }, [setAudience]);

  return (
    <Overview>
      <Overview.Content className="SurveyDetail">
        <TitleRow title={title} setTitle={setTitle} t={t} />
        <Row>
          <Group>
            <Label text={t('survey:form_label_survey_type')} />
            <SurveyType survey={survey} />
          </Group>
        </Row>
        <Row>
          <Group>
            <Label text={t('survey:form_label_is_anonymous')} />
            <Toggle value={survey.settings.is_anonymous} disabled />
          </Group>
        </Row>
        <Row>
          <Group>
            <Label text={t('survey:form_label_audience')} />
            {
              showAudienceModal &&
              <AudienceModalForm
                close={closeAudienceModal}
                initialValues={{ audience }}
                onSubmit={onAudienceModalSubmit}
              />
            }
            <div className="SurveyAudience">
              <AudienceBadge
                predicates={audience.predicates}
                type={audience.predicate_type}
              />

              <Icon
                onClick={() => {
                  setShowAudienceModal(true);
                  setShouldSendAudience(true);
                }}
                className="audienceEdit"
                type="edit"
              />
            </div>
          </Group>
        </Row>
        {
          (survey.survey_type !== ESurveyTypes.ONGOING) && (
            <ExpiresAtRow expiresAt={expiresAt} onChange={setExpiresAt} />
          )
        }
        <Row>
          <Group>
            <Label text={t('survey:form_label_published_date')} />
            {survey.settings.publish_at
              ? <div className="SurveyDetailItem">{moment(survey.settings.publish_at).format('MMM D, YYYY HH:mm')}</div>
              : <small><Trans i18nKey="survey:form_no_publish_date" /></small>}
          </Group>
        </Row>
        <Row>
          <Group flex="0 0 250px">
            <Label text={t('survey:form_label_created_at')} />
            {survey.created_at && <div className="SurveyDetailItem">{moment(survey.created_at).format('MMM D, YYYY HH:mm')}</div>}
          </Group>
          {survey.created_by && (
            <Group flex="1 0 auto">
              <Label text={t('survey:form_label_created_by')} />
              <ImageItem item={survey.created_by} />
            </Group>
          )}
        </Row>
        <Buttons
          loading={loading}
          disabled={!fieldsChanged && !shouldSendAudience}
          t={t}
          onReset={onReset}
          onSubmit={onSubmit}
        />
      </Overview.Content>
    </Overview>
  );
};

const mapStateToProps = (state: StoreState, { match }: Props) => ({
  survey: surveySelector.item(state, match.params.id),
});

export default connect(mapStateToProps)(withTranslation()(
  pageWrapper(EEventNames.VISITED_SURVEY_DETAIL_PAGE)(SurveyDetailContainer)
));
