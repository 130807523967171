import React, { useState, useEffect, useMemo } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import * as R from 'ramda';
import * as moment from 'moment';

import ProfileImage from '@common/components/profile-image';
import { Select } from '@common/components/form/inputs/select';
import { EPredicateFilters } from '@common/components/predicates-filter';
import Overview from '@common/components/overview';
import Modal from '@common/components/modal';
import Spinner from '@common/components/spinner';
import Table from '@common/components/table';
import Icon from '@common/components/icon';
import Alert from '@common/components/alert';
import SearchBar from '@common/components/search-bar';
import Permission from '@common/components/permission';
import Tooltip from '@common/components/tooltip';
import Placeholder from '@common/components/placeholder';
import { Button } from '@common/components/button';
import FiltersToolbar from '@common/components/filters-toolbar/filters-toolbar';

import * as organisationSelector from '@modules/organisation/selectors/organisation';

import { EComponentTypes, EPlanPackageConfig, EPredicateFields } from '@common/definitions';
import Api from '@common/services/api';
import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';
import { usePredicateState } from '@common/hooks/use-predicate-state';

import { QuestionIcon } from '../../components/question-icon';
import CompareTable from '../../components/compare-table';
import { EQuestionTypes } from '../../definitions';
import * as surveySelector from '../../selectors/surveys';
import { track, pageWrapper, EEventNames } from '../../../../../client/analytics';
import ReportQuestion from './report-question';

const { Row } = Overview.Content;

const CompareQuestionComponent = ({
  surveyId, question, compareType, fetchData, t,
}) => {
  const [data, setData] = React.useState(null);
  const [showPrivacyAlert, setShowPrivacyAlert] = React.useState(false);
  const [sortDescending, setSortDescending] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      setData(null);

      if (!question) return;

      const result = await fetchData(surveyId, question.id, compareType);

      track(EEventNames.COMPARED_SURVEY_QUESTION, {
        questionType: question.type,
        comparisonType: compareType,
      });

      setShowPrivacyAlert(result.data.some((row) => !row.answer_statistics));

      const newData = result.data
        .filter((row) => row.answer_statistics)
        .map((row) => ({
          ...row,
          average: row.answer_statistics.reduce((acc, r) => acc + r.step * r.answer_count, 0) / row.question_response_count || 0,
          maxCount: row.answer_statistics.reduce((acc, r) => Math.max(acc, r.answer_count), 0),
          mostVotedOption: row.answer_statistics[0].option && row.answer_statistics.reduce((acc, r) => {
            if (r.answer_count > 0 && (!acc || acc.answer_count < r.answer_count)) return r;

            return acc;
          }, null),
        }));

      setData(newData);
    })();
  }, [question && question.id, compareType]);

  if (!data) return <Spinner centered size="large" />;
  if (data.length === 0) return <Placeholder title={t('survey:no_data')} />;

  return (
    <>
      {data && showPrivacyAlert && (
        <Alert
          type="warning"
          className="ReportCompare__Alert"
          title={t('survey:compare_privacy', { context: compareType })}
        />
      )}
      <CompareTable
        data={data}
        compareType={compareType}
        sortDescending={sortDescending}
        setSortDescending={setSortDescending}
      />
    </>
  );
};

const OptionComponent = ({ option, isSelected, onSelect }) => (
  <div
    className={`ReportCompare__Option${isSelected ? ' ReportCompare__Option--selected' : ''}`}
    onClick={onSelect && (() => onSelect(option))}
  >
    <QuestionIcon type={option.type} />
    <div className="ReportCompare__Option__Text">
      {option.index + 1}
      .
      {option.text}
    </div>
  </div>
);

const allowedCompareTypes = [
  EQuestionTypes.MULTIPLE_CHOICE,
  EQuestionTypes.SLIDER,
];

const SurveySummaryContainer = ({
  t,
  history,
  match,
  report = { questions: [] },
  survey,
  fetchReport,
  fetchAnswers,
  fetchData,
  fetchUsers,
  organisation,
}) => {
  const [selectedQuestionId, setSelectedQuestionId] = React.useState(null);
  const [compareType, setCompareType] = React.useState('networks');
  const [showResponsesModal, setShowResponsesModal] = React.useState(false);
  const [showUsersModal, setShowUsersModal] = React.useState(false);
  const [usersModalTab, setUsersModalTab] = React.useState('received');
  const [usersSearch, setUsersSearch] = React.useState(null);
  const [networkItems, setNetworkItems] = useState([]);

  const [_filters, functionItems] = useSelector((state) => {
    return [
      state.survey.filters[match.params.id],
      state.organisation.functions.items,
    ];
  });
  const orgId = organisation.id;

  useEffect(() => {
    const url = `/v4/organisations/${orgId}/networks?include_all=true`;
    Api.get(url).then((response) => {
      setNetworkItems(response.data);
    });
  }, [orgId, setNetworkItems]);

  const filters = useMemo(() => {
    if (!_filters) return { networks: [], functions: [] };
    return {
      networks: networkItems.length ? _filters.networks.map(({ id, allowed }) => {
        const networkItem = networkItems.find((n) => n.id === id);
        return { ...networkItem, disabled: !allowed };
      }) : [],
      functions: functionItems ? _filters.functions.map(({ id, allowed }) => {
        return { ...functionItems[id], disabled: !allowed };
      }) : [],
    };
  }, [_filters, networkItems, functionItems]);

  const [predicates, setPredicates] = usePredicateState({
    networks: filters.networks,
    functions: filters.functions
  });

  const {
    isAvailable: canGenerateReport,
    showUpgradeModal: showGenerateReportUpgradeModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.SURVEYS_RESULTS_EXPORT);

  const {
    isAvailable: canCompareQuestionResults,
    showUpgradeModal: showCompareResultsUpgradeModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.SURVEYS_ADVANCED_ANALYTICS);

  const [networkIds, functionIds] = useMemo(() => {
    return [
      predicates.find((p) => p.filter === EPredicateFilters[EPredicateFields.NETWORK]).value.value,
      predicates.find((p) => p.filter === EPredicateFilters[EPredicateFields.FUNCTION]).value.value,
    ];
  }, [predicates]);

  React.useEffect(() => {
    (async () => {
      try {
        await fetchReport(match.params.id, { networkIds, functionIds });
      } catch (err) {
        const overviewUrl = match.path.split('/:id')[0];
        history.replace(overviewUrl);
      }
    })();
  }, [networkIds, functionIds]);

  const handleCompareQuestion = (questionId) => {
    setSelectedQuestionId(questionId);
    setShowResponsesModal(true);
  };

  const questions = report && report.questions.map(({ question: q }, i) => R.assoc('index', i, q));

  const selectedQuestion = R.find(R.propEq('id', selectedQuestionId), questions);

  const handleGenerateReport = () => {
    const query = Api.utils.toQuery({
      networkIds,
      functionIds,
    });

    history.push(`/report/surveys/${match.params.id}?${query}`);
  };

  const handleGenerateCompareReport = () => {
    if (!selectedQuestion) return;

    const query = Api.utils.toQuery({
      compareType,
      questionType: selectedQuestion.type,
    });

    history.push(`/report/surveys/${match.params.id}/compare/${selectedQuestion.id}?${query}`);
  };

  const handleOpenUsersModal = (tab) => {
    setUsersModalTab(tab);
    setShowUsersModal(true);
  };

  const hasData = survey && report;
  if (!hasData) {
    return <Spinner centered size="large" />;
  }

  const hasDisabledOptions = networkItems.some((n) => n.disabled) || Object.values(functionItems).some((f) => f.disabled);

  return (
    <>
      <Overview>
        <Overview.Content>
          <Row>
            <Row.StatisticsCard
              color="#007AFF"
              title={t('survey:counts_received')}
              count={report.assigned_count || 0}
              action={survey?.settings.is_anonymous === false && (
                <a onClick={() => handleOpenUsersModal('received')}>
                  <Trans i18nKey="survey:view_employees" />
                </a>
              )}
            />
            <Row.Divider />
            <Row.StatisticsCard
              color="#FFCC00"
              title={t('survey:counts_waiting_response')}
              count={report.assigned_count - report.responded_count || 0}
              action={survey?.settings.is_anonymous === false && (
                <a onClick={() => handleOpenUsersModal('waiting')}>
                  <Trans i18nKey="survey:view_employees" />
                </a>
              )}
            />
            <Row.Divider />
            <Row.StatisticsCard
              color="#34C759"
              title={t('survey:counts_completed')}
              count={report.responded_count || 0}
              action={(
                <a onClick={() => history.push(`/admin/surveys/${match.params.id}/responses`)}>
                  <Trans i18nKey="survey:view_responses" />
                </a>
              )}
            />
            <Row.Divider />
            <Row.StatisticsCard
              color="#FF9500"
              title={t('survey:count_completion_rate')}
              count={`${Math.round((report.responded_count / report.assigned_count) * 100 || 0)}%`}
            />
          </Row>
        </Overview.Content>
      </Overview>
      <Modal
        fullWidthContent
        list
        show={showUsersModal}
        onHide={() => setShowUsersModal(false)}
        header={(
          <Modal.TabHeader
            onChange={setUsersModalTab}
            active={usersModalTab}
            options={[{
              title: t('survey:counts_received'),
              value: 'received',
              count: report.assigned_count || 0,
            }, {
              title: t('survey:counts_waiting_response'),
              value: 'waiting',
              count: report.assigned_count - report.responded_count || 0,
            }, {
              title: t('survey:counts_completed'),
              value: 'completed',
              count: report.responded_count || 0,
            }]}
          />
        )}
        content={(
          <>
            <SearchBar defaultValue={usersSearch} onSearch={setUsersSearch} />
            <Table
              columns={[{
                size: 35,
              }, {
                className: 'Table__Cell__Title fs-exclude',
                label: t('survey:users_column_name'),
              }, {
                label: t('survey:users_column_assigned_date'),
              }, usersModalTab === 'completed' && {
                label: t('survey:users_column_completed_date'),
              }]}
              data={{
                useCursor: true,
                onFetch: fetchUsers,
                filter: {
                  surveyId: report.survey_id,
                  tab: usersModalTab,
                  search: usersSearch,
                },
              }}
              renderRow={({ item }) => [
                <ProfileImage size={35} user={item} />,
                item.full_name,
                moment(item.survey_assignment.created_at).format('DD-MM-YYYY'),
                usersModalTab === 'completed' && moment(item.survey_assignment.completed_at).format('DD-MM-YYYY'),
              ]}
              rowProps={{
                mask: true,
              }}
            />
          </>
        )}
      />
      <div className="Form__Row PredicatesFilter__Filters SurveyReport__Filters">
        <FiltersToolbar
          predicates={predicates}
          setPredicates={setPredicates}
          organisation={organisation}
        />
        {hasDisabledOptions && (
          <Alert title={t('survey:filtered_functions')} />
        )}
        <Permission
          component={EComponentTypes.SURVEYS_PREMIUM}
          deniedComponent={(
            <Button
              type="primary"
              className="SurveyReport__Filters__Report"
              disabled
              icon="assignment__filled"
            >
              <span>
                <Trans i18nKey="survey:generate_report" />
              </span>
              <Tooltip title={t('survey:paid_plan')}>
                <Icon type="lock" className="PremiumLock" />
              </Tooltip>
            </Button>
          )}
        >
          <Button
            type="primary"
            icon="assignment__filled"
            onClick={canGenerateReport ? handleGenerateReport : showGenerateReportUpgradeModal}
            className="SurveyReport__Filters__Report"
          >
            <Trans i18nKey="survey:generate_report" />
          </Button>
        </Permission>
      </div>
      <Overview>
        <Overview.Header>
          <Overview.Header.Title><Trans i18nKey="survey:response_summary" /></Overview.Header.Title>
        </Overview.Header>
        <Overview.Content>
          {
            report.questions.map(({
              question,
              answer_statistics: answerStatistics,
              question_response_count: responseCount
            }, index) => (
              <ReportQuestion
                key={question.id}
                number={index + 1}
                question={question}
                report={report}
                responseCount={responseCount}
                answerStatistics={answerStatistics}
                fetchAnswers={fetchAnswers}
                networkIds={networkIds}
                functionIds={functionIds}
                handleCompareQuestion={canCompareQuestionResults ? handleCompareQuestion : showCompareResultsUpgradeModal}
              />
            ))
          }
          <Modal
            list
            fullWidthContent
            show={showResponsesModal}
            onClose={() => setShowResponsesModal(false)}
            size="large"
            title={t('survey:compare_responses', { context: compareType })}
            content={(
              <>
                <div className="modal-header ReportCompare__Header">
                  <Select
                    clearable={false}
                    searchable={false}
                    className="ReportCompare__Header__SelectQuestion"
                    value={selectedQuestion}
                    valueKey="id"
                    options={questions.filter((q) => allowedCompareTypes.includes(q.type))}
                    onChange={(option) => setSelectedQuestionId(option.id)}
                    valueComponent={({ value }) => <OptionComponent option={value} />}
                    OptionComponent={OptionComponent}
                  />
                  <Select
                    clearable={false}
                    searchable={false}
                    value={compareType}
                    onChange={(option) => setCompareType(option.value)}
                    options={[{
                      value: 'networks',
                      label: t('survey:compare_by_networks'),
                    }, {
                      value: 'functions',
                      label: t('survey:compare_by_functions'),
                    }]}
                  />
                  <Button type="primary" icon="assignment__filled" onClick={handleGenerateCompareReport}>
                    <Trans i18nKey="survey:generate_report" />
                  </Button>
                </div>
                <CompareQuestionComponent
                  surveyId={report.survey_id}
                  question={selectedQuestion}
                  compareType={compareType}
                  fetchData={fetchData}
                  t={t}
                />
              </>
            )}
          />
        </Overview.Content>
      </Overview>
    </>
  );
};

const mapStateToProps = (state, { match: { params } }) => {
  return {
    survey: surveySelector.item(state, params.id),
    report: surveySelector.report(state, params.id),
    organisation: organisationSelector.selected(state),
  };
};

const mapDispatchToProps = {
  fetchReport: require('../../actions').fetchReport,
  fetchAnswers: require('../../actions').fetchAnswers,
  fetchData: require('../../actions').fetchData,
  fetchUsers: require('../../actions').fetchUsers,
};

const ConnectedSurveySummaryContainer = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(
    pageWrapper(EEventNames.VISITED_SURVEY_SUMMARY_PAGE)(
      SurveySummaryContainer,
    ),
  ),
);

export default ConnectedSurveySummaryContainer;
