import React, {
  memo, useMemo, useCallback, Dispatch, SetStateAction, useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';

import { Select } from '@common/components/form/inputs/select';
import Card from '@common/components/card';
import { EPlanPackageConfig, EPredicateFields } from '@common/definitions';
import { StoreState } from '@common/types/store';
import { AudienceInput } from '@common/components/form/audience';
import { PulseSurveyResponse } from '@modules/survey/types/objects';
import { Column } from './questions-editor';
import { usePlanPackageAudienceHandlers } from '@common/hooks/use-plan-package-audience-handlers';

const frequencies = [
  '1w', '2w', '3w', '1m', '3m'
];
const questionsPerRoundOptions = ['1', '2', '3', '4', '5'].map((n: string) => {
  return { label: n, value: n };
});

type SettingsEditorProps = {
  survey: PulseSurveyResponse;
  setSurvey: Dispatch<SetStateAction<PulseSurveyResponse | null>>;
  formValues: any;
  change: any;
  loading: boolean;
};

const SettingsEditor = memo(({
  survey, setSurvey, loading, formValues
}: SettingsEditorProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    setSurvey((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        data: {
          ...prev.data,
          audience: formValues.audience,
        }
      };
    });
  }, [formValues, setSurvey]);

  const planPackageAudienceHandlers = usePlanPackageAudienceHandlers({
    packageIdAddFilters: EPlanPackageConfig.SURVEYS_AUDIENCE_SELECTOR,
    packageIdDaysInService: EPlanPackageConfig.SURVEYS_AUDIENCE_SELECTOR_ONBOARDING,
  });

  const settings = survey?.data?.settings;
  const questionsPerRound = (settings?.questions_per_round || '').toString();
  const frequency = settings?.frequency || '';

  const setFrequency = useCallback((option: any) => {
    setSurvey((response: any) => {
      return {
        ...response,
        data: {
          ...response?.data,
          settings: {
            ...response?.data?.settings,
            frequency: option.value
          }
        }
      };
    });
  }, [setSurvey]);

  const setQuestionsPerRound = useCallback((option: any) => {
    setSurvey((response: any) => {
      return {
        ...response,
        data: {
          ...response?.data,
          settings: {
            ...response?.data?.settings,
            questions_per_round: parseInt(option.value)
          }
        }
      };
    });
  }, [setSurvey]);

  const frequencyOptions = useMemo(() => {
    return frequencies.map((freq: string) => {
      return {
        label: t(`survey:pulse_survey_settings_frequency_${freq}`),
        value: freq
      };
    });
  }, [t]);

  return (
    <form>
      <Column className={`SettingsEditor${loading ? ' loading' : ''}`}>
        <div className="titleBar">
          <h2>
            {t('survey:survey_tabs_settings')}
          </h2>
        </div>
        <Card>
          <p>{t('survey:pulse_survey_settings_frequency_description')}</p>

          <div>
            <div className="titledInput frequency">
              <h3>{t('survey:pulse_survey_frequency')}</h3>
              <Select
                clearable={false}
                options={frequencyOptions}
                value={frequency}
                onChange={setFrequency}
              />
            </div>
            <div className="titledInput questionsPerRound">
              <h3>{t('survey:pulse_survey_questions_per_round')}</h3>
              <Select
                clearable={false}
                options={questionsPerRoundOptions}
                value={questionsPerRound}
                onChange={setQuestionsPerRound}
              />
            </div>
          </div>
        </Card>

        <Card containerClassName="audienceCard">
          <div className="whoWillReceive">
            <AudienceInput
              name="audience"
              {...planPackageAudienceHandlers}
              enabledFilters={[
                EPredicateFields.USER,
                EPredicateFields.LANGUAGE,
                EPredicateFields.NETWORK,
                EPredicateFields.FUNCTION,
                EPredicateFields.DAYS_IN_SERVICE,
              ]}
            />
          </div>
        </Card>
      </Column>
    </form>

  );
});

const form = 'pulse-survey-settings-editor';

// @ts-expect-error
const ReduxFormSettingsEditor = reduxForm({ form })(SettingsEditor);

const InitialisedReduxFormSettingsEditor = memo((props: any) => {
  const { survey, loading } = props;

  const initialValues = useMemo(() => {
    return {
      audience: {
        predicate_type: survey.data.audience.predicate_type,
        predicates: survey.data.audience.predicates
      },
    };
  }, [survey]);
  const formValues = useSelector((state: StoreState) => {
    return (
      state.form[form]?.values ||
      // if react-redux has not populated redux state yet it means
      // that the component is setting up, so let's have formValues be
      // the initial values, this solves ONE-773
      initialValues
    );
  });

  return (
    <ReduxFormSettingsEditor
      {...props}
      initialValues={initialValues}
      formValues={formValues}
      loading={loading}
    />
  );
});

export default InitialisedReduxFormSettingsEditor;
