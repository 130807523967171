import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import * as moment from 'moment';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import mergeNamesText from '../../../../common/utils/merged-names-text';
import Overview from '../../../../common/components/overview';
import Table from '../../../../common/components/table';
import Permission from '../../../../common/components/permission';
import Placeholder from '../../../../common/components/placeholder';
import Alert from '../../../../common/components/alert';
import Tooltip from '../../../../common/components/tooltip';
import Icon from '../../../../common/components/icon';
import { Button } from '../../../../common/components/button';
import ImageItem from '../../../../common/components/image-item/user';
import SearchBar from '../../../../common/components/search-bar';
import ResponseContainer from '../response';
import * as surveySelector from '../../selectors/surveys';
import { EComponentTypes } from '../../../../common/definitions';

require('./responses.scss');

const SurveyResponsesContainer = ({
  history, match, survey, fetchResponses, downloadResponses, t,
}) => {
  const [search, setSearch] = React.useState(null);
  const [isDownloading, setDownloading] = React.useState(false);

  const handleDownload = async () => {
    try {
      setDownloading(true);

      await downloadResponses(survey.id, `${survey.title} - ${t('survey:survey_tabs_responses')} ${moment().format('DD-MM-YYYY')}`);
    } finally {
      setDownloading(false);
    }
  };

  const handleClick = (item) => {
    history.push(`/admin/surveys/${survey.id}/responses/${item.id}`);
  };

  return (
    <>
      {survey && survey.settings.is_anonymous && (
        <Alert
          type="warning"
          title={t('survey:anonymous_feedback')}
        >
          <Trans i18nKey="survey:anonymous_feedback_description" components={[<a href="https://help.oneteam.io/articles/5144399-enquetes" target="_blank" rel="noreferrer" />]} />
        </Alert>
      )}
      <Overview>
        <Overview.Header>
          <Overview.Header.Title>
            <Trans i18nKey="survey:responses_per_employee" />
            <div className="pull-right">
              <Permission
                component={EComponentTypes.SURVEYS_PREMIUM}
                deniedComponent={(
                  <Button
                    icon="download" size="large" disabled
                    className="downloadAllResponses"
                  >
                    <span>
                      <Trans i18nKey="survey:download_all_responses" />
                    </span>
                    <Tooltip title={t('survey:paid_plan')}>
                      <Icon type="lock" className="PremiumLock" />
                    </Tooltip>
                  </Button>
                )}
              >
                <Button
                  className="downloadAllResponses" icon="download" size="large"
                  onClick={handleDownload} isLoading={isDownloading}
                >
                  <Trans i18nKey="survey:download_all_responses" />
                </Button>
              </Permission>
            </div>
          </Overview.Header.Title>
        </Overview.Header>
        <div className="ResponsesContainer">
          <Overview.Content>
            <SearchBar onSearch={setSearch} />
            <Table
              columns={[{
                className: 'Table__Cell__Title fs-exclude',
                label: t('survey:responses_header_name'),
              }, {
                label: t('survey:responses_header_date_responded'),
              }, {
                label: t('survey:responses_header_community'),
              }, {
                label: t('survey:responses_header_function_group'),
              }]}
              data={{
                useCursor: true,
                onFetch: fetchResponses,
                onPostFetch: (request, strategy) => {
                  if (!strategy && request.data[0]) history.replace(`/admin/surveys/${survey.id}/responses/${request.data[0].id}`);
                },
                filter: {
                  surveyId: survey.id,
                  search,
                },
              }}
              selectedRow={match.params.responseId}
              renderRow={({ item }) => [
                <>
                  {(item.user && <ImageItem item={item.user} />) || t('survey:survey_response_anonymous')}
                </>,
                moment(item.created_at).format('MMM D, YYYY HH:mm'),
                (item.meta_data && mergeNamesText(item.meta_data.networks, 1, 'name', (name) => <b key={`n-${name}`}>{name}</b>)) || '',
                (item.meta_data && mergeNamesText(item.meta_data.functions, 1, 'name', (name) => <b key={`f-${name}`}>{name}</b>)) || '',
              ]}
              rowProps={(item) => ({
                onClick: () => {
                  handleClick(item);
                },
              })}
              placeholder={(
                <Placeholder
                  image="/static/images/responses-placeholder.png"
                  title={t('survey:responses_placeholder_title')}
                />
              )}
            />
          </Overview.Content>
          <Route
            path="/admin/surveys/:surveyId/responses/:id"
            component={ResponseContainer}
          />
        </div>
      </Overview>
    </>
  );
};

const mapStateToProps = (state, { match }) => ({
  survey: surveySelector.item(state, match.params.id),
});

const mapDispatchToProps = {
  fetchResponses: require('../../actions').fetchResponses,
  downloadResponses: require('../../actions').downloadResponses,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(pageWrapper(EEventNames.VISITED_SURVEY_RESPONSES_PAGE)(SurveyResponsesContainer)));
